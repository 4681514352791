<template>
  <v-container
    id="message-insights"
    fluid
    tag="section"
  >
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <template>
      <v-dialog
        v-model="dialog"
        max-width="600px"
      >
        <v-card v-if="selectedRow && selectedRow.stats">
          <v-card-title>
            <span class="headline">Sentiment stats for cell {{ selectedRow.CellTo }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="statsWrapper"
                >
                  <div
                    v-for="stat in selectedRow.stats"
                    :key="stat.text"
                    class="statDataWrapper"
                  >
                    <div
                      class="statDataRow"
                    >
                      <div class="dataLabel">
                        Text:
                      </div>
                      <div class="dataValue">
                        {{ stat.text }}
                      </div>
                    </div>
                    <div class="statDataRow">
                      <div class="dataLabel">
                        Sentiment:
                      </div>
                      <div class="dataValue">
                        {{ selectedRow.stats[0].sentiment || 0 }}
                      </div>
                    </div>
                    <div class="statDataRow">
                      <div class="dataLabel">
                        Positive Score:
                      </div>
                      <div class="dataValue">
                        {{ selectedRow.stats[0].positive_score || 0 }}
                      </div>
                    </div>
                    <div class="statDataRow">
                      <div class="dataLabel">
                        Neural Score:
                      </div>
                      <div class="dataValue">
                        {{ selectedRow.stats[0].neural_score || 0 }}
                      </div>
                    </div>
                    <div class="statDataRow">
                      <div class="dataLabel">
                        Negative Score:
                      </div>
                      <div class="dataValue">
                        {{ selectedRow.stats[0].negative_score || 0 }}
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="secondary"
              text
              @click="closeStatDialog"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-row>
      <v-col
        cols="12"
        md="4"
        offset-md="8"
        offset-lg="8"
      >
        <v-select
          v-model="selectedSurvey"
          :items="surveyOptions"
          item-text="name"
          item-value="id"
          label="Select a survey"
          solo
          class="dropdown"
          @change="updateSelectedSurvey"
        />
      </v-col>
    </v-row>
    <!--  Show if message/group not selected  -->
    <v-row v-if="!isSurveyDataAvailable">
      <v-col
        cols="12"
      >
        <div class="selectMessageInfo">
          Select a survey to continue
        </div>
      </v-col>
    </v-row>

    <v-row v-if="isSurveyDataAvailable">
      <v-col
        cols="12"
      >
        <div class="messageInfoWrapper">
          <div class="messageMetaData">
            <h2>Survey Name: <span class="lightText">{{ selectedSurvey.name }}</span></h2>
            <h3>Response count: <span class="lightText">{{ surveyScore.ResponseCnt }}</span></h3>
          </div>
          <div class="questionsWrapper">
            <div class="questionWrapper">
              <survey-bar-chart
                label="Question 1"
                :max-height="surveyScore.QTN1Max+1"
                :values="[[surveyScore.QTN1Max], [surveyScore.QTN1], [surveyScore.QTN1Min]]"
                :colors="[graphColors.q1.highest, graphColors.q1.avg, graphColors.q1.lowest]"
              />

              <h4>Question 1: <span>{{ selectedSurvey.data.question1 }}</span></h4>
              <p>
                <v-icon
                  small
                  class="mr-2"
                  :style="`color: ${graphColors.q1.highest}`"
                >
                  mdi-checkbox-blank-circle
                </v-icon>Highest Score = {{ surveyScore.QTN1Max }}
              </p>
              <p>
                <v-icon
                  small
                  class="mr-2"
                  :style="`color: ${graphColors.q1.avg}`"
                >
                  mdi-checkbox-blank-circle
                </v-icon>Avg Score = {{ surveyScore.QTN1 }}
              </p>
              <p>
                <v-icon
                  small
                  class="mr-2"
                  :style="`color: ${graphColors.q1.lowest}`"
                >
                  mdi-checkbox-blank-circle
                </v-icon>Lowest Score = {{ surveyScore.QTN1Min }}
              </p>
            </div>
            <div class="questionWrapper">
              <survey-bar-chart
                label="Question 2"
                :max-height="surveyScore.QTN2Max+1"
                :values="[[surveyScore.QTN2Max], [surveyScore.QTN2], [surveyScore.QTN2Min]]"
                :colors="[graphColors.q2.highest, graphColors.q2.avg, graphColors.q2.lowest]"
              />

              <h4>Question 2: <span>{{ selectedSurvey.data.question2 }}</span></h4>
              <p>
                <v-icon
                  small
                  class="mr-2"
                  :style="`color: ${graphColors.q2.highest}`"
                >
                  mdi-checkbox-blank-circle
                </v-icon>Highest Score = {{ surveyScore.QTN2Max }}
              </p>
              <p>
                <v-icon
                  small
                  class="mr-2"
                  :style="`color: ${graphColors.q2.avg}`"
                >
                  mdi-checkbox-blank-circle
                </v-icon>Avg Score = {{ surveyScore.QTN2 }}
              </p>
              <p>
                <v-icon
                  small
                  class="mr-2"
                  :style="`color: ${graphColors.q2.lowest}`"
                >
                  mdi-checkbox-blank-circle
                </v-icon>Lowest Score = {{ surveyScore.QTN2Min }}
              </p>
            </div>
            <div class="questionWrapper">
              <survey-bar-chart
                label="Question 3"
                :max-height="surveyScore.QTN3Max"
                :values="[[surveyScore.QTN3Max], [surveyScore.QTN3], [surveyScore.QTN3Min]]"
                :colors="[graphColors.q3.highest, graphColors.q3.avg, graphColors.q3.lowest]"
              />
              <h4>Question 3: <span>{{ selectedSurvey.data.question3 }}</span></h4>
              <p>
                <v-icon
                  small
                  class="mr-2"
                  :style="`color: ${graphColors.q3.highest}`"
                >
                  mdi-checkbox-blank-circle
                </v-icon>Highest Score = {{ showSentiments(surveyScore.QTN3Max) }}
              </p>
              <p>
                <v-icon
                  small
                  class="mr-2"
                  :style="`color: ${graphColors.q3.avg}`"
                >
                  mdi-checkbox-blank-circle
                </v-icon>Avg Score = {{ showSentiments(surveyScore.QTN3) }}
              </p>
              <p>
                <v-icon
                  small
                  class="mr-2"
                  :style="`color: ${graphColors.q3.lowest}`"
                >
                  mdi-checkbox-blank-circle
                </v-icon>Lowest Score = {{ showSentiments(surveyScore.QTN3Min) }}
              </p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="isSurveyDataAvailable">
      <v-col
        cols="12"
      >
        <v-data-table
          :headers="headers"
          :items="surveyRows"
          item-key="id"
          sort-by="Status"
        >
          <template v-slot:item.actions="{ item }">
            {{ item.sentimentLabel }}
            <v-btn
              color="primary"
              outlined
              x-small
              class="ml-4"
              @click="showSurveyStats(item)"
            >
              Details
            </v-btn>
          </template>
          <template v-slot:no-data>
            <p class="mb-4 mt-4">
              No data to display
            </p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <div class="py-3" />
  </v-container>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import SurveyBarChart from '../../../components/SurveyBarChart'
  // import css
  import 'frappe-charts/dist/frappe-charts.min.css'
  export default {
    components: {
      SurveyBarChart,
    },
    props: {
      contactId: {
        required: false,
        type: Number,
        default: null,
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      dialog: false,
      headers: [
        { text: 'Cell To', value: 'CellTo' },
        { text: 'Question 1 Score', value: 'QTN1' },
        { text: 'Question 2 Score', value: 'QTN2' },
        { text: 'Question 3 Sentiment', value: 'actions', sortable: false },
      ],
      selected: [],
      snackbar: false,
      graphColors: {
        q1: {
          highest: '#34495e',
          lowest: '#f39c12',
          avg: '#27ae60',
        },
        q2: {
          highest: '#16a085',
          lowest: '#7f8c8d',
          avg: '#e67e22',
        },
        q3: {
          highest: '#8e44ad',
          lowest: '#00d8d6',
          avg: '#ff5e57',
        },
      },
      notification: 'Unknown',
      isOverlayVisible: false,
      surveyOptions: [],
      selectedSurvey: null,
      isSurveyDataAvailable: false,
      selectedRow: null,
      surveyScore: null,
    }),

    async created () {
      this.usremail = localStorage.getItem('username')
      await this.initialize()
    },

    mounted () {

    },

    methods: {
      async initialize () {
        // First fetch all available surveys
        try {
          this.isOverlayVisible = true
          const surveysResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}surveys?code=${this.apiCode}`)
          this.surveyOptions = []
          surveysResponse.data.forEach((survey) => {
            this.surveyOptions.push({
              id: survey.twilioSurveyFID,
              name: survey.surveyName,
              data: survey,
            })
          })
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
        }
      },

      updateSelectedSurvey (selectedSurveyId) {
        this.isSurveyDataAvailable = false
        // Reset selected row
        this.selectedRow = null
        // Reset all table rows
        this.rows = []
        // Set selected survey
        this.selectedSurvey = this.surveyOptions.find(s => s.id === selectedSurveyId)
        this.fetchSurveyData()
      },

      showSentiments (sentimentNumber) {
        switch (parseInt(sentimentNumber)) {
          case 1: {
            return 'Neutral'
          }
          case 0: {
            return 'Positive'
          }
          case 2: {
            return 'Negative'
          }
        }
      },

      async fetchSurveyData () {
        try {
          this.isSurveyDataAvailable = false
          this.isOverlayVisible = true
          // Request 1: Get survey score
          const surveyScoreResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}surveyscores/${this.selectedSurvey.id}?code=${this.apiCode}`)
          this.surveyScore = surveyScoreResponse.data[0]
          // Request 2: Get survey rows
          const surveyRowsResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}surveyinsights/${this.selectedSurvey.id}?code=${this.apiCode}`)
          this.surveyRows = surveyRowsResponse.data.map(row => ({
            ...row,
            sentimentLabel: this.showSentiments(row.sentiment),
          }))
          this.isSurveyDataAvailable = true
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
        }
      },

      async showSurveyStats (row) {
        this.isOverlayVisible = true
        this.selectedRow = row
        try {
          const statResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}surveysentenceinsights/${row.textanalysisID}?code=${this.apiCode}`)
          this.selectedRow.stats = statResponse.data
          this.dialog = true
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = true
        }
      },
      closeStatDialog () {
        this.dialog = false
      },
    },

  }

</script>

<style scoped lang="scss">
  .messageInfoWrapper {
    width: 100%;
    background-color: white;
    min-height: 100px;
    display: flex;
        display: block;
    .messageMetaData {
      width: 100%;
      padding: 40px 20px 30px 20px;
      .lightText {
        font-weight: 300!important;
        padding-left: 7px;
        font-size: 1.3rem;
      }
      h2 {
        margin-bottom: 2rem;
      }
      h3 {
        margin-bottom: -25px;
      }
      .dateDisplay {
        margin-bottom: 75px;
      }
      p {
        margin-bottom: 3px;
      }

    }
  }
  .questionsWrapper {
    display: flex;
      justify-content: center;
      align-items: center;
      // @media only screen and (max-width: 668px) {
      //   flex-direction: column;
      // }
      .questionWrapper {
        flex: 1;
        padding: 30px 20px;
        h4 {
          margin-bottom: 2rem;
          span {
            font-weight: 400;
          }
        }
        p {
          margin-bottom: 0.5rem;
        }
      }
  }
  .selectMessageInfo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    margin-bottom: 200px;
  }
    .spinner {
    margin-left: 17rem;
  }
  @media only screen and (max-width: 968px) {
    .spinner {
      margin-left: 0;
    }
  }
  .statusFilterWrapper {
    margin-top: 25px;
    margin-bottom: -30px;
  }

  .statsWrapper {
    height: 400px;
    overflow-y: scroll;
  }

  .statDataWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    background-color: rgba(233, 233, 233, 0.87);
    padding: 25px 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 11px solid rgb(250, 185, 22);
    margin-bottom: 60px;
    flex-direction: column;
    .statDataRow {
      margin: 10px 0 10px 0;
      border-bottom: 1px solid rgb(216, 216, 216);
      width: 70%;
      display: flex;
      // justify-content: center;
      align-items: center;
      .dataLabel {
        flex: 2;
        font-weight: 600;;
      }
      .dataValue {
        flex: 2;
        li {
        margin-left: -10px;
          margin-bottom: 0.1px;
          font-size: 0.9rem;
        }
      }
    }
  }

</style>
