<template>
  <graph-bar
    :width="300"
    :height="220"
    :axis-min="0"
    :axis-max="maxHeight"
    :labels="[ label ]"
    :values="values"
    :colors="colors"
  >
    <note :text="'Bar Chart'" />
    <tooltip
      :names="[ label ]"
      :position="'left'"
    />
    <legends
      :names="[ label ]"
      :filter="false"
    />
  </graph-bar>
</template>

<script>
  import GraphBar from 'vue-graph/src/components/bar'

  export default {
    name: 'SurveyBarChart',
    components: {
      GraphBar,
    },
    props: {
      label: {
        type: String,
        required: true,
      },
      maxHeight: {
        type: Number,
        required: true,
      },
      values: {
        type: Array,
        required: true,
      },
      colors: {
        type: Array,
        required: true,
      },
    },
    data: function () {
      return {
        names: ['Question 1'],
        // values: [
        //   [10],
        //   [40],
        //   [30],
        // ],
      }
    },
    mounted () {
      this.renderChart(this.chartData, this.options)
    },
  }
</script>
